
import jsbarcode from "jsbarcode";

import Vue from "vue";

export default Vue.extend({
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    jsbarcode(this.$refs.canvas, this.code.padStart(12, "0"), {
      width: 1,
      height: 25,
      margin: 5,
      fontSize: 10,
      displayValue: false,
    });
  },
});
