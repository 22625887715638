
import http from "@/http";
import { Building } from "@/interfaces";

import MyBarcode from "@/components/MyBarcode.vue";

import Vue from "vue";

export interface DataType {
  building?: Building;
}

export default Vue.extend({
  components: {
    MyBarcode,
  },
  data(): DataType {
    return {
      building: undefined,
    };
  },
  async mounted() {
    this.building = undefined;

    const { id } = this.$route.params;

    const url = `buildings/${id}`;

    const { data } = await http.get<Building>(url);

    this.building = data;
  },
});
